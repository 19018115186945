body {
	font-family: "Roboto";
	padding: 0;
	margin: 0;
}

#root {
	width: 100%;
	height: calc(100vh);
	height: 100dvh !important;
}

.main-container {
	display: flex;
	flex-wrap: wrap;
	/* height: 100vh; */
	height: inherit;
}

.bp4-tab-panel {
	width: 350px;
	/* height: 100vh !important; */
	/* height: 100dvh !important; */
	overflow: auto !important;
	padding: 10px !important;
	background-color: #fbfbfb;
	margin-right: 3px;
}

:focus {
	outline: none;
	outline-offset: none;
	-moz-outline-radius: none;
}

.main-container .tabpanel .vertical-tabs {
	background-color: #fff;
	height: 100%;
	position: relative;
	display: flex;
}

.rwt__tablist {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	flex-grow: 0;
	border-right: 1px solid #ddd;
	margin-right: 1rem;
}

.main-container .tabpanel .rwt__tab {
	padding: 0;
	position: relative;
}

.rwt__tab {
	background: 0 0;
	border: 0;
	font-family: inherit;
	font-size: inherit;
	padding: 1rem 2rem;
}

.main-container .tabpanel .vertical-tabs .edit-box {
	border-bottom: 1px solid #f1f1f1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 86px;
	cursor: pointer;
}

img {
	vertical-align: middle;
	border-style: none;
}

.main-container .tabpanel .vertical-tabs .edit-box span {
	color: #8492a6;
	font-size: 10px;
	line-height: 11px;
	margin-top: 10px;
}

.canvas-panel {
	display: flex;
	width: 100%;
	flex: 1;
	flex-direction: column;
	position: relative;
	overflow: hidden;
}

.main-area .canvas-area {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: calc(100%);
	/* overflow: auto; */
}

.main-area {
	width: 100%;
	height: 100%;
	position: relative;
	outline: 0;
	flex: 1 1 0%;
	background-color: rgba(232, 232, 232, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
	flex-direction: column;
}

.main-area .canvas-area .canvas-container {
	box-shadow: 3px 3px 3px #888;
	border: 1px solid #f4f4f4;
	background-color: #fff;
}

.left-side-panel {
	transition: width 0.1s;
	width: 367px;
}

.bp4-tab,
.text-center,
h1,
h3 {
	text-align: center;
}

.bp4-button,
.go3457971912,
h1,
h3,
select {
	cursor: pointer;
}

.bp4-slider,
.col,
.go2452636514,
.side-panel {
	position: relative;
}

.col {
	padding-right: 0;
	padding-left: 0;
	max-width: 100%;
	width: 100%;
}

.left-side-panel .side-panel-container .first-title {
	font-size: 14px;
	line-height: 15px;
	font-weight: 400;
	color: #48507a;
}

.row {
	margin-right: 0;
	margin-left: 0;
	display: flex;
	flex-wrap: wrap;
}

h1,
h3 {
	font-weight: 600;
	font-size: 36px;
}

.bp4-button.bp4-minimal {
	background: 0 0;
	box-shadow: none;
}

.bp4-button {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	border: none;
	border-radius: 2px;
	font-size: 14px;
	justify-content: center;
	padding: 5px 10px;
	text-align: left;
	vertical-align: middle;
	min-height: 30px;
	min-width: 30px;
	color: #1c2127;
}

.bp4-button-text {
	flex: 0 1 auto;
}

.bp4-button.bp4-minimal:hover {
	background: rgba(143, 153, 168, 0.15);
	box-shadow: none;
	color: #1c2127;
	text-decoration: none;
}

.go4160152499 {
	height: 100%;
	overflow: auto;
	margin-top: 35px;
}

.go2497765715 {
	width: 33%;
	float: left;
}

.go3168597731 {
	padding: 0 0 40px;
	width: 100%;
}

.go2452636514 {
	border-radius: 5px;
	overflow: hidden;
	box-shadow: none;
}

.go3457971912 {
	width: 75%;
	display: block;
}

.toolbar-container,
.toolbar-container .leftbar,
.toolbar-container .rightbar {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.toolbar-container .leftbar .font-familiy-container {
	width: 126px;
	margin-right: 0;
	color: #555c83;
}

.font-familiy-container {
	width: 127px;
	margin-left: 2px;
}

.toolbar-container .leftbar .font-familiy-container #font-picker {
	box-shadow: none;
}

#font-picker {
	width: 130px;
}

#font-family {
	width: 120px;
	border: 2px solid #ececec;
	border-radius: 4px;
}

select {
	word-wrap: normal;
}

button,
select {
	text-transform: none;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

.font-color-container {
	/* margin-left: 10px; */
	margin-right: 5px;
	position: relative;
	width: auto;
	height: 34px;
}

.font-style-container {
	display: flex;
	align-items: center;
	margin-right: 5px;
}

.duplicate,
.lockObj,
.position,
.rightbar .position,
.transparency {
	margin-right: 5px;
}

.font-style-container img {
	cursor: pointer;
	opacity: 0.5;
}

.bp4-tab-indicator-wrapper {
	background: #18191b !important;
}

.bp4-tab:not([aria-disabled="true"]):hover {
	color: #18191b !important;
	background-color: rgba(0, 0, 0, 0.06) !important;
}

.bp4-tab[aria-selected="true"]:hover {
	color: #fff !important;
	background-color: #18191b !important;
}

.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab[aria-selected="true"] {
	color: #fff !important;
	border-radius: 0 !important;
	background-color: #18191b !important;
}

.bp4-popover2.bp4-popover2-content-sizing .bp4-popover2-content {
	padding: 10px;
}

.left-tab {
	padding-top: 6px;
}

.left-tab-text {
	font-size: 12px;
}

.bp4-slider {
	height: 40px;
	min-width: 150px;
	width: 100%;
	cursor: default;
	outline: 0;
	-webkit-user-select: none;
	user-select: none;
}

.rightbar {
	float: right;
	width: 30%;
}

.side-panel {
	display: flex;
	height: 100% !important;
	padding: 0 !important;
	background-color: #fff;
	box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 1px 1px rgb(17 20 24 / 20%);
	width: 100%;
	z-index: 10;
}

.bp4-tab {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 72px;
	padding-top: 15px;
	text-align: center;
	font-size: 12px;
	cursor: pointer;
}

.leftbar .undoChange {
	margin-right: 10px;
}

.footer {
	width: 100%;
}

.footer .copywrite {
	float: right;
	margin-top: 20px;
	margin-right: 10px;
}

.bp4-tab {
	/* margin-bottom: 25px; */
	margin: 0 !important;
}

.solid-color {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 2px;
	box-shadow: 0 0 2px rgb(16 22 26 / 30%);
	cursor: pointer;
}

.zomm-canvas {
	width: auto;
	opacity: 0.8;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pagination {
	opacity: 0.8;
	overflow-x: auto;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	padding: 0px 0px 5px;
	border-top: 1px solid #18191b;
}
.pagination .bp4-button {
	width: 100%;
	min-width: 100px;
}

.pagination .bp4-button:not([class*="bp4-intent-"]) {
	background: transparent;
	color: #18191b;
	box-shadow: none;
	border: 0px solid #d5d5d5;
	border-radius: 0px;
}

.pagination > div {
	position: relative;
}

.pagination .bp4-button:not([class*="bp4-intent-"]):hover {
	background: transparent;
	box-shadow: none;
}

.pagination .active .bp4-button:not([class*="bp4-intent-"]):hover {
	background: #dadada;
}

.pagination div.active:before {
	background-color: #fff;
	border-color: #ccc;
}

.pagination div.active:after {
	background: #fff;
	border-color: #ccc;
}

.pagination div:before {
	content: "";
	position: absolute;
	left: -13px;
	top: 0px;
	width: 14px;
	height: 14px;
	border-radius: 0 14px 0 0;
	-moz-border-radius: 0 14px 0 0;
	-webkit-border-radius: 0 14px 0 0;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0);
	border-top: 1px solid rgba(0, 0, 0, 0);
	border-right: 1px solid rgba(0, 0, 0, 0);
}

.pagination div:after {
	content: "";
	position: absolute;
	right: -13px;
	top: 0px;
	width: 14px;
	height: 14px;
	border-radius: 14px 0 0 0;
	-moz-border-radius: 14px 0 0 0;
	-webkit-border-radius: 14px 0 0 0;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0);
	border-top: 1px solid rgba(0, 0, 0, 0);
	border-left: 1px solid rgba(0, 0, 0, 0);
}

.pagination div.active .bp4-button {
	border-color: rgba(0, 0, 0, 0) #ccc #ccc;
	background: #dadada;
	border-radius: 0 0 9px 9px;
	-moz-border-radius: 0 0 9px 9px;
	-webkit-border-radius: 0 0 9px 9px;
}
.pagination div .bp4-button:before {
	content: "";
	position: absolute;
	left: -10px;
	top: -2px;
	width: 13px;
	height: 13px;
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	background: rgba(0, 0, 0, 0);
	z-index: 1;
	display: block;
}

.pagination div .bp4-button:after {
	content: "";
	position: absolute;
	right: -10px;
	top: -2px;
	width: 13px;
	height: 13px;
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	background: rgba(0, 0, 0, 0);
	z-index: 1;
}

.pagination div.active .bp4-button:after {
	background: #dadada;
}

.pagination div.active .bp4-button:before {
	background: #dadada;
	opacity: 1;
}

.bottom-section .pagination::-webkit-scrollbar-track {
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #f5f5f5;
	background-clip: padding-box;
}

.bottom-section .pagination::-webkit-scrollbar {
	height: 3px;
	background-color: #f5f5f5;
	background-clip: padding-box;
}

.bottom-section .pagination::-webkit-scrollbar-thumb {
	background-color: #555;
}

span.currentpage {
	padding: 5px 10px;
	min-width: 70px;
	text-align: center;
}

.bottom-section {
	position: relative;
	bottom: 0px;
	width: 100%;
}

#zoom-out {
	margin-right: 10px;
}

.form-select {
	width: 100%;
	border-radius: 0.25rem;
	padding: 0.375rem 2.25rem 0.375rem 0.75rem;
	color: #212529;
	background-color: #fff;
}

.tag-select {
	margin-top: 15px;
}

select:focus {
	outline: none;
}

.template-row {
	display: grid;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	grid-template-columns: repeat(2, minmax(auto, 1fr));
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 20px;
}

.template-column {
	width: 100%;
}

.template-column img {
	width: 100%;
	cursor: pointer;
}

#cover-spin {
	position: fixed;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 9999;
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(360deg);
	}
}

#cover-spin::after {
	content: "";
	display: block;
	position: absolute;
	left: 48%;
	top: 40%;
	width: 40px;
	height: 40px;
	border-style: solid;
	border-color: transparent #000 #000;
	border-width: 4px;
	border-radius: 50%;
	-webkit-animation: 0.8s linear infinite spin;
	animation: 0.8s linear infinite spin;
}

.elements {
	float: left;
	width: 32%;
	cursor: pointer;
	margin-bottom: 15px;
}

.elements img {
	height: 74px;
	width: 75%;
}

.element_cat {
	margin-bottom: 10px;
}

.bp4-tabs.bp4-vertical > .bp4-tab-list {
	border-right: 1px solid #dfe0e0 !important;
	background-color: #f0f0f0;
}

.bp4-tabs.bp4-vertical > .bp4-tab-panel::-webkit-scrollbar-track {
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #f5f5f5;
	background-clip: padding-box;
}

.bp4-tabs.bp4-vertical > .bp4-tab-panel::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
	background-clip: padding-box;
}

.bp4-tabs.bp4-vertical > .bp4-tab-panel::-webkit-scrollbar-thumb {
	background-color: #555;
}

.bp4-button:not([class*="bp4-intent-"]) {
	color: #fff;
	background-color: #18191b;
}

.bp4-button .bp4-icon,
.bp4-button .bp4-icon-standard,
.bp4-button .bp4-icon-large {
	color: #fff;
}

.bp4-button:not([class*="bp4-intent-"]):hover {
	background-clip: padding-box;
	background-color: #fff;
	box-shadow: inset 0 0 0 1px rgba(24, 25, 27, 0.5),
		0 1px 2px rgba(24, 25, 27, 0.5);
	color: #000;
}

.bp4-button:not([class*="bp4-intent-"]):hover .bp4-icon > svg:not([fill]) {
	fill: #18191b;
}

.bp4-button:not([class*="bp4-intent-"]):disabled:hover,
.bp4-button:not([class*="bp4-intent-"]).bp4-disabled:hover {
	background-color: rgba(211, 216, 222, 0.5);
	background-clip: padding-box;
	box-shadow: none;
	color: rgba(95, 107, 124, 0.6);
	cursor: not-allowed;
	outline: none;
}

.bp4-button:not([class*="bp4-intent-"]):disabled:hover
	.bp4-icon
	> svg:not([fill]),
.bp4-button:not([class*="bp4-intent-"]).bp4-disabled:hover
	.bp4-icon
	> svg:not([fill]) {
	fill: rgba(95, 107, 124, 0.6) !important;
}

.bp4-tabs.bp4-vertical
	> .bp4-tab-list
	.bp4-tab-indicator-wrapper
	.bp4-tab-indicator {
	background-color: transparent !important;
}

.tab-content .close-tab {
	position: absolute;
	right: -31px;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 96px;
	width: 20px;
	cursor: pointer;
	z-index: 10;
	padding: 0;
	margin: 0;
	transition: all 0.5s;
	background: #f0f0f0;
	box-shadow: 0 0 0 1px rgb(17 20 24 / 10%), 0 1px 1px rgb(17 20 24 / 20%);
	border-top-right-radius: 90% 50%;
	border-bottom-right-radius: 90% 50%;
	min-height: unset;
	min-width: unset;
}

.tab-content .close-tab:hover,
.tab-content .close-tab:focus {
	background: #c1c1c1;
	box-shadow: none;
}

.tab-content .close-tab .bp4-icon {
	z-index: 2;
	color: #18191b !important;
}

.tab-content .close-tab:hover::after {
	background-color: #18191b;
}

.tab-content .close-tab .bp4-icon > svg:not([fill]) {
	fill: #18191b;
}

.tab-content .close-tab:hover .bp4-icon > svg:not([fill]) {
	fill: #2c2d2e;
}

.top-header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	max-height: 68px;
	z-index: 9;
}

.top-header .site-logo img {
	width: 60px;
	height: auto;
	object-fit: contain;
}

nav.bp4-navbar {
	background-color: #f0f0f0;
}

/* Canvas Pages */
.page-list {
	position: absolute;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0.8;
	text-align: center;
	bottom: 25px;
}

.page-list button {
	width: 16%;
	margin-right: 10px;
}

/* hide scroll */

.bp4-tabs.bp4-vertical::-webkit-scrollbar,
.bp4-tab-list::-webkit-scrollbar,
nav.bp4-navbar::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

.bp4-tabs.bp4-vertical::-webkit-scrollbar-thumb,
.bp4-tab-list::-webkit-scrollbar-thumb,
nav.bp4-navbar::-webkit-scrollbar-thumb {
	background: transparent;
	border-radius: 0;
}

.bp4-tabs.bp4-vertical::-webkit-scrollbar-thumb:hover,
.bp4-tab-list::-webkit-scrollbar-thumb:hover,
nav.bp4-navbar::-webkit-scrollbar-thumb:hover {
	background: transparent;
}

.bp4-tabs.bp4-vertical::-webkit-scrollbar-track,
.bp4-tab-list::-webkit-scrollbar-track,
nav.bp4-navbar::-webkit-scrollbar-track {
	background: transparent;
	box-shadow: none;
}

.draggable-item {
	border: 1px solid #ccc;
	margin-bottom: 5px;
	padding: 10px;
	background: #f2f2f2;
	cursor: move;
}

/* Custom Structure Changes */
.main-section {
	display: flex;
	height: calc(100% - 68px);
	width: 100%;
}

.bp4-navbar,
.bp4-navbar-group {
	min-height: 50px;
	height: inherit;
}

.bp4-navbar-group.bp4-align-left {
	overflow-x: auto;
	max-width: 100%;
}

.selectDropdown {
	display: flex;
}

select#categoryName {
	padding: 0px 10px;
	border-radius: 0px;
}

.bp4-menu span.bp4-icon {
	margin-right: 5px;
}

#imageList img {
	max-width: 160px;
	cursor: pointer;
	margin: 1px;
	border-radius: 5px;
}

@media screen and (max-width: 1200px) {
	.pagination {
		justify-content: start;
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media screen and (min-width: 769px) {
	.bp4-tab-indicator-wrapper {
		width: 100% !important;
	}
}

@media screen and (max-width: 1023px) {
	.main-section {
		flex-direction: column-reverse;
	}

	.pagination {
		display: flex;
	}

	.main-container .top-header {
		order: 1;
	}

	.tabpanel {
		height: auto;
		width: 100%;
		max-height: initial;
	}

	.main-container {
		flex-direction: column-reverse;
	}

	.side-panel {
		flex-direction: column-reverse;
		width: 100%;
		overflow-y: auto;
	}

	.bp4-tab-list {
		flex-direction: row !important;
		min-width: min-content;
		overflow: auto;
		width: 100%;
	}

	.bp4-tabs.bp4-vertical {
		flex-direction: column-reverse !important;
		flex-wrap: nowrap;
		width: 100vw;
		background-color: #f8f8f8e6 !important;
	}

	.bp4-tabs.bp4-vertical > .bp4-tab-list {
		border-right: none !important;
		border-top: 1px solid #dfe0e0 !important;
	}

	.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab {
		height: 64px;
		padding: 5px;
		width: 72px;
	}

	.bp4-tabs.bp4-vertical {
		width: 100%;
		overflow: auto;
	}
	.bp4-tab-panel {
		width: 100vw;
		bottom: 64px;
		z-index: 100;
		height: calc(60vh) !important;
		padding: 30px 15px 15px !important;
		margin-top: 0;
	}

	.bp4-tab-panel[aria-hidden="false"],
	.bp4-tab-panel[aria-hidden="true"] {
		transition: all 0.5s linear;
	}

	nav.bp4-navbar {
		max-width: 100vw;
		overflow-x: auto;
		overflow-y: hidden;
	}

	.tab-content .close-tab {
		border-bottom-left-radius: 90% 50%;
		border-bottom-right-radius: 0;
		border-top-left-radius: 90% 50%;
		border-top-right-radius: 0;
		left: 50%;
		right: unset;
		top: -38px;
		-webkit-transform: translate(-50%, 0%) rotate(-90deg);
		transform: translate(-50%, 0%) rotate(-90deg);
	}
}
